import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import Byline from "../components/byline";
import { CONSTANTS } from "../components/constants";
import Layout from "../components/layout";
import Seo from "../components/seo";

export const infographicQuery = graphql`
  query infographicData($infographicId: Int!, $authorIds: [Int]!) {
    infographic: strapiInfographics(strapiId: { eq: $infographicId }) {
      slug
      strapiId
      title
      published_at(formatString: "MMMM DD, YYYY")
      content
      childrenFile {
        url
        publicURL
        alt
        childImageSharp {
          fullsize: gatsbyImageData
          small: gatsbyImageData(width: 400, quality: 80)
        }
      }
      author {
        id
        firstname
        lastname
      }
    }
    authors: allStrapiAuthorPages(
      filter: { user: { id: { in: $authorIds } } }
    ) {
      edges {
        node {
          user {
            id
            firstname
            lastname
          }
          jobTitle
          longBio
          shortBio
          image {
            formats {
              thumbnail {
                url
              }
              small {
                url
              }
              medium {
                url
              }
              large {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const Infographic = ({ pageContext, data }) => {
  return (
    <Layout altBg={true}>
      <Seo
        title={`${data.infographic.title}`}
        image={
          data.infographic.childrenFile.length > 0 &&
          data.infographic.childrenFile[0].publicURL
        }
        meta={[
          {
            property: `og:url`,
            content: `${CONSTANTS.URL}/research/${data.infographic.slug}/`,
          },
        ]}
      />
      <div className="section post">
        <div className="row gtr-uniform aln-center">
          <div className="col-7 col-9-medium col-12-small post">
            <div className="heading medium-bottom-margin">
              <Link to="/research/">
                <h1>research</h1>
              </Link>
              <h2>{data.infographic.title}</h2>
              <Byline
                byline={data.infographic.author}
                authors={data.authors}
                published_at={data.infographic.published_at}
              />
            </div>
            <ReactMarkdown
              children={data.infographic.content}
              components={{
                img: ({ node, ...imgProps }) => {
                  let fileIndex = data.infographic.childrenFile.findIndex(
                    (file) => file.url === imgProps.src
                  );
                  return (
                    <GatsbyImage
                      image={
                        data.infographic.childrenFile[fileIndex]?.childImageSharp
                          .fullsize
                      }
                      alt={data.infographic.childrenFile[fileIndex]?.alt || ""}
                    />
                  );
                },
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Infographic;
